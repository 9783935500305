import { Button, Image, Text, VStack } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import * as React from 'react';

export const NoMatchPage: React.FC = () => {
  const router = useRouter();
  return (
    <VStack p="12" spacing="4" textAlign="center">
      <Image
        src="https://tradewing-public.s3.us-east-2.amazonaws.com/tradewing-logo.png"
        alt="Tradewing"
        width="50px"
        height="50px"
      />
      <Text textStyle="xlBodyStrong">Page Not Found</Text>
      <Text>
        Please check that you have the correct URL and try again. If you need
        help finding and accessing your community, please contact
        support@tradewing.com.
      </Text>
      <Button onClick={() => router.push('/')}>Return to Site</Button>
    </VStack>
  );
};
